@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: black;
}

.custom-modal .ant-modal-close-x {
  color: white; /* Change this to your desired color */
}

  .blue_blur {
    background-image: url("/public/p-background.png");
    background-repeat: no-repeat;
    background-size: cover;
  }